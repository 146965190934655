const $ = window.jQuery;

const contactChat = {

    init() {

        const chatContainer = $('#chatContainer');

        const msg_ini = '<div class="chat-bubble align-self-start mb-4"><div class="d-flex align-items-end gap-4"><div class="avatar avatar-default"></div><div class="msg-bubble">';
        const msg_ini_reply = '<div class="chat-bubble reply align-self-end mb-4"><div class="d-flex align-items-end gap-4"><div class="msg-bubble d-flex align-items-center gap-4">';
        const msg_end = '</div></div></div">';
        const msg_end_reply = '</div><div class="avatar avatar-reply"></div></div></div">';

        const msg1 = 'Olá';
        const msg2 = 'Eu sou Stark';
        const msg3 = 'Em que posso ajudar?';

        const msg4 = 'Meu nome é <div class="reply reply-input nome d-flex align-items-center gap-4"><input type="text" id="input_nome"><button id="reply_nome" class="button simple-arrow no-animation"></button></div>';
        const msg5 = 'Meu email é <div class="reply reply-input email d-flex align-items-center gap-4"><input type="text" id="input_email"><button id="reply_email" class="button simple-arrow no-animation"></button></div>';
        const msg6 = 'Meu telefone é <div class="reply reply-input telefone d-flex align-items-center gap-4"><input type="text" id="input_telefone" class="telefone"><button id="reply_telefone" class="button simple-arrow no-animation"></button></div>';

        const msg7 = 'É um prazer ter você aqui, ';
        const msg8 = 'Em que posso te ajudar hoje?';

        const msg9 = '<div id="reply_assunto" class="d-flex flex-column gap-4 py-3"><button data-assunto="orcamento" class="button preto tf-none">Quero solicitar um orçamento</button><button data-assunto="trabalho" class="button preto tf-none">Quero trabalhar na <strong>Qualitare</strong>.</button><button data-assunto="contato" class="button preto tf-none">Enviar uma mensagem</button></div>';

        const msg10 = 'Você falou com a pessoa certa!';
        const msg11 = 'Em poucas palavras, você pode me contar um pouco mais sobre suas necessidades e ambições?';

        const msg12 = '<div><div class="py-16">Estou querendo ajuda com:</div><div class="reply reply-input mensagem d-flex align-items-end gap-1"><textarea type="text" id="input_mensagem"></textarea><button id="reply_mensagem" class="button simple-arrow no-animation"></button></div></div>';

        const msg13 = 'Incrível, estou animado para saber mais.';
        const msg14 = 'Obrigado por entrar em contato!';
        const msg15 = 'Em breve entraremos em contato por você pelo e-mail ou telefone informado.';

        const msg16 = '<div><div class="py-16">Por favor, nos envie seu currículo:</div></div>';
        const msg17 = '<div><div class="py-16">Anexe seu currículo:</div><div class="reply reply-input mensagem d-flex align-items-end gap-1"><input size="40" id="reply_curriculo" accept=".pdf" aria-invalid="false" type="file" name="your-curriculo" /><button id="reply_curriculo" class="button simple-arrow no-animation"></button></div></div>';

        var user_nome = "";
        var user_email = "";
        var user_telefone = "";
        var user_assunto = "";
        var user_mensagem = "";

        setTimeout(function () {
            chatContainer.append(msg_ini + msg1 + msg_end);
            scrollChat();
        }, 500);
        setTimeout(function () {
            chatContainer.append(msg_ini + msg2 + msg_end);
            scrollChat();
        }, 1000);
        setTimeout(function () {
            chatContainer.append(msg_ini + msg3 + msg_end);
            scrollChat();
        }, 1500);
        setTimeout(function () {
            chatContainer.append(msg_ini_reply + msg4 + msg_end_reply);
            scrollChat();
        }, 2000);

        //NOME
        $('body').on('click', '#reply_nome', function () {
            if ($('#input_nome').val()) {
                user_nome = $('#input_nome').val();
                $('#form-your-name').val(user_nome);
                $('.reply-input').closest('.chat-bubble').remove();
                chatContainer.append(msg_ini_reply + 'Meu nome é ' + user_nome + msg_end_reply);
                scrollChat();
                chatContainer.append(msg_ini_reply + msg5 + msg_end_reply);
                scrollChat();
                $('#input_email').focus();
            } else {
                alert('Por favor, informe seu nome!');
            }
        });

        $('body').on('keypress', '#input_nome', function (e) {
            var key = e.which;
            if (key == 13)  // the enter key code
            {
                $('#reply_nome').click();
                return false;
            }
        });

        //EMAIL
        $('body').on('click', '#reply_email', function () {
            if ($('#input_email').val()) {
                user_email = $('#input_email').val();
                $('#form-your-email').val(user_email);
                $('.reply-input').closest('.chat-bubble').remove();
                chatContainer.append(msg_ini_reply + 'Meu email é ' + user_email + msg_end_reply);
                scrollChat();
                chatContainer.append(msg_ini_reply + msg6 + msg_end_reply);
                scrollChat();
                $('#input_telefone').focus();
            } else {
                alert('Por favor, informe seu email!');
            }
        });

        $('body').on('keypress', '#input_email', function (e) {
            var key = e.which;
            if (key == 13)  // the enter key code
            {
                $('#reply_email').click();
                return false;
            }
        });

        //TELEFONE
        $('body').on('click', '#reply_telefone', function () {
            if ($('#input_telefone').val()) {
                user_telefone = $('#input_telefone').val();
                $('#form-your-tel').val(user_telefone);
                $('.reply-input').closest('.chat-bubble').remove();
                chatContainer.append(msg_ini_reply + 'Meu telefone é ' + user_telefone + msg_end_reply);
                scrollChat();
                setTimeout(function () {
                    chatContainer.append(msg_ini + msg7 + user_nome + '!' + msg_end);
                    scrollChat();
                }, 500);
                setTimeout(function () {
                    chatContainer.append(msg_ini + msg8 + msg_end);
                    scrollChat();
                }, 1000);
                setTimeout(function () {
                    chatContainer.append(msg_ini_reply + msg9 + msg_end_reply);
                    scrollChat();
                }, 1500);
            } else {
                alert('Por favor, informe seu telefone!');
            }
        });

        $('body').on('keypress', '#input_telefone', function (e) {
            var key = e.which;
            if (key == 13)  // the enter key code
            {
                $('#reply_telefone').click();
                return false;
            }
        });

        //ASSUNTO
        $('body').on('click', '#reply_assunto button', function () {
            user_assunto = $(this).data('assunto');
            switch (user_assunto) {
                case "orcamento":
                    $('#form-your-subject').val("Solicitação de orçamento");
                    break;
                case "trabalho":
                    $('#form-your-subject').val("Trabalhe Conosco");
                    break;
                case "contato":
                    $('#form-your-subject').val("Mensagem");
                    break;
            }
            if ($(this).data('assunto') !== "trabalho") {
                setTimeout(function () {
                    chatContainer.append(msg_ini + msg10 + msg_end);
                    scrollChat();
                }, 500);
                setTimeout(function () {
                    chatContainer.append(msg_ini + msg11 + msg_end);
                    scrollChat();
                }, 1000);
                setTimeout(function () {
                    chatContainer.append(msg_ini_reply + msg12 + msg_end_reply);
                    scrollChat();
                }, 1500);
            } else {
                //CURRICULO
                $('#form-your-subject').val("Trabalhe Conosco");
                setTimeout(function () {
                    chatContainer.append(msg_ini + msg16 + msg_end);
                    scrollChat();
                }, 500);
                setTimeout(function () {
                    chatContainer.append(msg_ini_reply + msg17 + msg_end_reply);
                    scrollChat();
                }, 1500);
            }
        });

        $('body').on('click', '#reply_curriculo+button', function () {
            $("#form-your-curriculo").prop("files", $("#reply_curriculo").prop("files"));
            $('#form-your-subject').val(user_assunto);
            // setTimeout(function () {
            //     chatContainer.append(msg_ini + msg10 + msg_end);
            //     scrollChat();
            // }, 500);
            setTimeout(function () {
                chatContainer.append(msg_ini + msg11 + msg_end);
                scrollChat();
            }, 1000);
            setTimeout(function () {
                chatContainer.append(msg_ini_reply + msg12 + msg_end_reply);
                scrollChat();
            }, 1500);
        });

        //MENSAGEM
        $('body').on('click', '#reply_mensagem', function () {
            if ($('#input_mensagem').val()) {
                user_mensagem = $('#input_mensagem').val();
                $('#form-your-message').val(user_mensagem);
                $('.reply-input').closest('.chat-bubble').remove();
                chatContainer.append(msg_ini_reply + user_mensagem + msg_end_reply);
                scrollChat();
                setTimeout(function () {
                    chatContainer.append(msg_ini + msg13 + msg_end);
                    scrollChat();
                }, 500);
                setTimeout(function () {
                    chatContainer.append(msg_ini + msg14 + msg_end);
                    scrollChat();
                }, 1000);
                setTimeout(function () {
                    chatContainer.append(msg_ini + msg15 + msg_end);
                    scrollChat();
                }, 1500);
                $(".wpcf7-form input[type='submit']").click();
            } else {
                alert('Por favor, informe seu mensagem!');
            }
        });

        $('body').on('keypress', '#input_mensagem', function (e) {
            var key = e.which;
            if (key == 13)  // the enter key code
            {
                $('#reply_mensagem').click();
                return false;
            }
        });

        function scrollChat() {
            $("#chat .container").animate({ scrollTop: $('#chat .container').prop("scrollHeight") }, 480);
        }

    }

};

export default contactChat;