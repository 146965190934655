import bootstrap from "../../node_modules/bootstrap/dist/js/bootstrap";
import fancybox from "../../node_modules/@fancyapps/ui/dist/fancybox.esm";
import AOS from "../../node_modules/aos/dist/aos";
import owl from "../../node_modules/owl.carousel/dist/owl.carousel";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { smoothScroll } from "./components/gsap-smooth-scroll.js";

//Qualitare
import openMenu from "./components/OpenMenu";
import contactChat from "./components/ContactChat";

// Initialise our components on jQuery.ready…
jQuery(function ($) {

    openMenu.init();

    AOS.init({
        duration: 2000,
        once: true
    });

    // if ($('#chatContainer').length) {
    //     contactChat.init();
    // }

    if ($('#depoimentosCarousel').length) {
        $('#depoimentosCarousel').owlCarousel({
            margin: 0,
            nav: true,
            navContainer: '#depoimentosNav',
            dots: false,
            animateOut: 'fadeOut',
            items: 1,
            navText: ['<button class="button simple-arrow no-animation"></button>', '<button class="button simple-arrow no-animation"></button>']
        });
    }

    if ($('#valoresCarousel').length) {
        $('#valoresCarousel').owlCarousel({
            margin: 0,
            nav: true,
            smartSpeed: 1000,
            navContainer: '#valoresNav',
            dots: false,
            items: 1,
            navText: ['<button class="button simple-arrow no-animation"></button>', '<button class="button simple-arrow no-animation"></button>']
        });
    }

    //FILTRAR PROJETOS
    var tipo;
    var segmento;

    $('.filter-tipo').on('mouseenter', function () { $('.filter-tipo .dropdown-menu').addClass('show') });
    $('.filter-tipo').on('mouseleave', function () { $('.filter-tipo .dropdown-menu').removeClass('show') });
    $('.filter-tipo .dropdown-menu').on('click', 'li a', function (e) {
        e.preventDefault();
        tipo = $(this).data('tipo');
        var title = $(this).data('title');
        $('.filter-tipo .btn-toggle').html(title);
        $('.filter-tipo .dropdown-menu').removeClass('show');
        filtrarSegmento(tipo);
        setTimeout(
            function () {
                AOS.refresh();
            }, 1000);
    });

    $('.filter-segmento').on('mouseenter', function () { $('.filter-segmento .dropdown-menu').addClass('show') });
    $('.filter-segmento').on('mouseleave', function () { $('.filter-segmento .dropdown-menu').removeClass('show') });
    $('.filter-segmento .dropdown-menu').on('click', 'li a', function (e) {
        e.preventDefault();
        segmento = $(this).data('segmento');
        var title = $(this).data('title');
        $('.filter-segmento .btn-toggle').html(title);
        $('.filter-segmento .dropdown-menu').removeClass('show');
        filtrarProjetos(tipo, segmento);
        setTimeout(
            function () {
                AOS.refresh();
            }, 1000);
    });

    function filtrarProjetos(tipo, segmento) {
        $('#projetosLista').addClass('loading');
        $.ajax({
            type: 'POST',
            url: ajaxurl,
            data: { "action": "filtro-projetos", tipo: tipo, segmento: segmento },
            success: function (response) {
                $("#projetosLista").html(response);
                $('#projetosLista').removeClass('loading');
                AOS.refresh();
                return false;
            }
        });
    }

    function filtrarSegmento(tipo) {
        $.ajax({
            type: 'POST',
            url: ajaxurl,
            data: { "action": "filtro-segmentos", tipo: tipo },
            success: function (response) {
                $(".filter-segmento .dropdown-menu").html(response);
                $(".filter-segmento .btn-toggle").html('todos os segmentos');
                filtrarProjetos(tipo, '');
                AOS.refresh();
                return false;
            }
        });
    }



});

document.querySelectorAll('.accordion-collapse').forEach((t, i) => {
    t.addEventListener('shown.bs.collapse', event => {
        // ScrollTrigger.refresh();
        // AOS.refresh();
    })
    t.addEventListener('hidden.bs.collapse', event => {
        // ScrollTrigger.refresh();
        // AOS.refresh();
    })
})