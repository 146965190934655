const $ = window.jQuery;

const openMenu = {

    init() {
        var $button = $('.menu-toggle');
        $button.on('click', function(){
            $('#mainNav').toggleClass('open');
        });
    }

};

export default openMenu;